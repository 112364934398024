.search-bar-container + .filtertext {
  margin-top: 8px;
  margin-bottom: 8px;
}

.recent-package.entry {
  text-decoration: none;
  transition-duration: 250ms;
  transition-property: background-color;
  &:hover {
    cursor: pointer;
    background-color: orange;
    color: black;
  }
  &:focus .entry-name, .entry-name:hover {
    text-decoration: underline;
  }
}
