main.home {
  max-width: 800px;
  padding-left: 4rem;
  padding-right: 4rem;
}

h1 {
  margin-top: 10px;
  border-radius: 0.1em;
  text-align: center;
  color: white;
  background-color: rgb(77,77,77);
}

.diffify-remove {
  color: #FC727A;
}

.diffify-add {
  color: #A1E82C;
}

.diffify-remove, .diffify-add {
  font-weight: bold;
  font-variant-ligatures: none;
}

.button-container {
  margin-bottom: 20px;
}

.button-container > button {
  margin-bottom: 5px;
  width: 16em;
}

.button-container > button:first-child {
  margin-right: 25px;
}

.link-list, .new-list {
  list-style-type: none;
  padding: 0;
}

.new-list p {
  margin-bottom: 0;
  padding-inline-start: 20px;
}