.select-and-diff {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.package-selection {
  min-width: 275px;
  flex: 1 1 275px;
}

.package-selection-wrapper {
  position: sticky;
  top: 1.8em;
  z-index: 10;
}

.diff-section-wrapper {
  flex: 2 1 685px;
}