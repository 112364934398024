@mixin hide-shortcut {
  .keyboard-shortcut {
    display: none;
  }
  .tooltip-container {
    display: inline;
  }
}

.diff-section {
  // Default style of keyboard-shortcut class
  .keyboard-shortcut {
    display: none;
    font-size: 0.9rem;
    vertical-align: middle;
  }

  // Show when something inside diff-section has visible focus
  &:has(:focus-visible) {
    .keyboard-shortcut {
      display: inline;
    }
    .tooltip-container {
      display: none;
    }
  }

  // But hide when the thing with focus is an input element
  // We need to use the shift key for typing so keyboard shortcuts are disabled
  &:has(input:focus) {
    @include hide-shortcut();
  }  
 
  // Also hide the keyboard shortcut if the element in question, or its tooltip, is already in focus
  .section-header:focus-within {
    @include hide-shortcut();
  }
}

// Hide keyboard shortcut if modal is visible
// We disable them in this case so as not to steal focus from the modal
body:has([role="dialog"])  {
  @include hide-shortcut;
}

