@mixin theme-buttons($added, $removed, $changed, $export, $text: white) {
  button.btn {
    opacity: 1;
    color: $text;
    font-weight: bold;
    min-height: 40px;
    &:hover span:first-child {
      text-decoration: underline overline $text;
    }
    &.active:focus-visible {
      box-shadow: inset 0 0 12px $text;
    }
    &.active {
      border: 1px solid transparent;
    }
  }

  button.btn-added {
    &:not(.active) {
      color: $added;
      border: 1px solid $added;
      &:focus-visible {
        box-shadow: inset 0 0 12px $added;
      }
      &:hover span:first-child {
        text-decoration: underline overline $added;
      }
    }
    &.active {
      background-color: $added;
    }
  }

  button.btn-removed {
    &:not(.active) {
      color: $removed;
      border: 1px solid $removed;
      &:focus-visible {
        box-shadow: inset 0 0 12px $removed;
      }
      &:hover span:first-child {
        text-decoration: underline overline $removed;
      }
    }
    &.active {
      background-color: $removed; 
    }
  }

  button.btn-changed {
    &:not(.active) {
      color: $changed;
      border: 1px solid $changed;
      &:focus-visible {
        box-shadow: inset 0 0 12px $changed;
      }
      &:hover span:first-child {
        text-decoration: underline overline $changed;
      }
    }
    &.active {
      background-color: $changed;
    }
  }

  button.btn-general {
    &:not(.active) {
      color: $export;
      border: 1px solid $export;
      &:focus-visible {
        box-shadow: inset 0 0 12px $export;
      }
      &:hover span:first-child {
        text-decoration: underline overline $export;
      }
    }
    &.active {
      background-color: $export;
    }
  }
}


@mixin theme-entries($added, $removed, $changed, $default, $text: black) {
  color: $text;
  background-color: $default;

  &[data-type="added"] {
    background-color: $added
  }
  
  &[data-type="removed"] {
    background-color: $removed
  }
  
  &[data-type="changed"] {
    background-color: $changed
  }
}


body {
  $opacity: 0.35;
  $added: rgba(#1e7e34, $opacity);
  $removed: rgba(#bd2130, $opacity);
  $changed: rgba(#d39e00, $opacity);
  $export: rgba(#0062cc, $opacity);
  $default: rgba(#0062cc, 0.15);

  .button-row {
    $added: rgba($added, $opacity);
    $removed: rgba($removed, $opacity);
    $changed: rgba($changed, $opacity);
    @include theme-buttons($added, $removed, $changed, $export, black);
  }

  .entry {
    $added: rgba($added, $opacity);
    $removed: rgba($removed, $opacity);
    $changed: rgba($changed, $opacity);
    @include theme-entries($added, $removed, $changed, $default);
  }

  .content {
    background-color: white;
  }

  nav.navbar, footer.footer {
    background-color: #f8f8f8;
  }

  i.type-symbol {
    color: black;
  }

  .badge {
    // React-bootstrap seems tp use important so we have to use it to to override that :(
    background-color: white !important;
    color: black;
  }

  .modal:focus-visible {
    box-shadow: inset 0 0 4rem black;
  }

  .nav-tabs button {
    --webkit-appearance: none;
    color: black;
    background-color: rgb(239,239,239);
  }

  // The weird .css- class is from react-select
  // If you can find a better way of overriding this please do so
  .main-panel,
  .select > div, .css-1uccc91-singleValue, .css-tlfecz-indicatorContainer, .form-control,
  .card .card-title, .toggle-text, 
  button.btn.btn-changed.active {
    color: black;
  }

  .button-row {
    button.btn {
      &:not(.active) {
        border: 1px solid black;
        &:focus-visible {
          box-shadow: inset 0 0 12px black;
        }
      }
      &.active :hover span:first-child {
        text-decoration: underline overline black;
      }
      &.btn-added, &.btn-removed, &.btn-changed {
        color: black;
        &:hover span:first-child {
          text-decoration: underline overline black;
        }
      }
    }
  }

  a, .btn-primary {
    color: blue;
  }

  .main-panel {
    .nav-link.active {
      color: black;
      font-weight: bold;
    }
  }

  input[type="text"].search-bar:focus-visible {
    box-shadow: inset 0 0 8px #222222;
  }
}


body[data-theme="cvd"] {
  $opacity: 0.8;
  $added: rgba(#1b9e77, $opacity);
  $removed: rgba(#d95f02, $opacity);
  $changed: rgba(#7570b3, $opacity);
  $export: black;
  $default: rgba(#0062cc, 0.15);
  $text: black;

  .button-row {
    @include theme-buttons($added, $removed, $changed, $export, $text);
  }

  .entry {
    @include theme-entries($added, $removed, $changed, $default, $text);
  }

  button.btn.btn-general.active {
    color: white;
    border: 1px solid $export;
    &:focus-visible {
      box-shadow: inset 0 0 12px white;
    }
    &:hover span:first-child {
      text-decoration: underline overline white;
    }
  }

  h1 {
    color: black;
    background-color: white;
    & > span {
      color: black;
    }
  }

  main {
    a, a:hover, a:focus {
      text-decoration: underline;
    }
  }
}


body[data-theme="dark"] {
  .main-panel {
    filter: invert(1) contrast(0.8);
    [id="main-logo"] {
      filter: invert(1) contrast(1.25);
    }
  }

  .sidebar {
    filter: invert(1) contrast(0.8);
    background-color: #f8f8f8;
    .sidebar-wrapper {
      filter: invert(1);
    }
  }

  a, button.btn {
    color: #0c74dc;
    &:hover {
      color: darkblue;
      border-color: darkblue;
    }
  }

  $opacity: 0.6;
  $added: rgba(#1e7e34, $opacity);
  $removed: rgba(#bd2130, $opacity);
  $changed: rgba(#d39e00, $opacity);
  $export: rgba(#0062cc, $opacity);
  $default: rgba(#0062cc, 0.3);
  $text: white;

  .button-row {
    filter: invert(1);
    @include theme-buttons($added, $removed, $changed, $export, $text);
  }

  .entry {
    filter: invert(1);
    @include theme-entries($added, $removed, $changed, $default, $text);
    table {
      filter: invert(1);
      span.changed {
        border-color: #004498;
      }
    }
  }

  i.type-symbol {
    color: white;
  }

  .card {
    border: 1px solid rgba(0,0,0,0.25);
  }

  [id="theme-selector"], .card {
    filter: contrast(0.9);
  }

  h1 {
    filter: invert(1);
  }

  .modal {
    filter: invert(1) contrast(0.8);
    .card {
      filter: none;
    }
  }

  h1 {
    background-color: black;
  }

  .nav-tabs {
    border-bottom-color: lightgrey;
  }

  .nav-item > button {
    background-color: rgb(230,230,230);
  }

  .entry a.linked-package > * {
    color: white;
  }
  
  .entry a.linked-package > *:hover {
    text-shadow: black 1px 0 1px;
  }

  .tooltip-popup {
    // Make background-color darker because blur effect
    // doesn't seem to work (on Chrome, at least) with filters
    background-color: rgba(0, 0, 0, 0.95);
  }
}