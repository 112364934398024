.card-header-tabs {
  margin-bottom: 0;
}

.button-row {
  margin-top: 12px;
  margin-bottom: 12px;
}

.button-row .btn-group {
    margin-top: 0.25em;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex-row.button-row {
  justify-content: flex-start;
}

.flex-row.entry {
  align-items: center;
}

.filtertext {
  flex: 1 1 auto;
  text-align: right;
}

.filtertext > p {
  margin-bottom: 0px;
}

input.form-control::placeholder {
  color: #aaaaaa;
}

.alert.entry > * {
  font-weight: bold;
}

i.type-symbol {
  color: white;
}

.entry[data-type="added"] {
  background-color: rgba(27,158,119, 0.5);
}

.entry[data-type="removed"] {
  background-color: rgb(217,95,2, 0.5);
}

.entry[data-type="changed"] {
  background-color: rgb(117,112,179, 0.5);
}

.entry .module-name {
  font-weight: normal;
  font-style: italic;
}

.entry .entry-name {
  flex: 1 1 0;
  padding-left: 0.25em;
  padding-right: 0.25em;
  overflow-x: auto;
  overflow-wrap: normal;
}

.entry.expandable {
  cursor: pointer;
}

.entry.unexpandable {
  cursor: default;
}

.entry:focus-visible {
  box-shadow: inset 0 0 12px currentColor;
  outline: none;
}

.entry a.linked-package {
  display: inline-block;
}

.entry a.linked-package > * {
  display: inline-block;
  color: black;
  transform: scale(1.1) translate(3px, 1px);
  z-index: 110;
}

.entry a.linked-package > *:hover {
  text-shadow: white 1px 0 1px;
  transform: scale(1.3) translate(3px, 1px);
}

.entry a.linked-package:focus {
  outline: none;
}

.entry a.linked-package:focus > * {
  transform: scale(1.1) translate(3px, 0px);
  border: 1px solid currentColor;
}

.entry a.linked-package:focus > *:hover {
  transform: scale(1.3) translate(3px, 0px);
}

input[type="text"].search-bar:focus-visible {
  box-shadow: inset 0 0 4px #888888;
  outline: none;
}

h5 {
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 0.25em;
}

.modal .modal-dialog .modal-header {
  display: flex;
}

.modal .modal-dialog .modal-header h3 {
  font-size: 1.4rem;
  overflow-x: auto;
  overflow-wrap: normal;
}

.modal .modal-dialog .modal-header .modal-close {
  color: black;
  border: none;
  border: 1px solid transparent;
}

.modal .modal-dialog .modal-header .modal-close:focus-visible {
  border: 1px solid black;
  box-shadow: inset 0 0 6px black;
}

.modal.show .modal-dialog {
  transform: translate(0, 0.5em);
}

@media (max-width: 449px) {
  .entry {
    padding-left: 0.3em;
    padding-right: 0.3em;
  }
}