.sidebar{
    &[data-color="black"]:after{
        @include icon-gradient(#282828, rgba(#111,.7));
    }
    .logo-img img {
      max-width: 40px;
      max-height: 40px;
    }
}

#bodyClick {
  position: absolute;
}
