body {
    font-family: sans-serif;
}

input {
    border: 1px solid #999;
    padding: 0.5rem;
    width: 100%;
}

.no-suggestions {
    color: #999;
    padding: 0.5rem;
}

.suggestions {
    overflow-y: auto;
    padding-bottom: 4px;
    position: relative;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    top: 100%;
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    margin-bottom: 8px;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
}

.suggestions li {
    padding: 0.5rem;
}

.suggestion-active {
    background-color: #2684FF;
    color: hsl(0, 0%, 100%);
    cursor: default;
    display: block;
    font-size: inherit;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
}

.suggestions li:hover {
    background-color: #DEEBFF;
    color: inherit;
    cursor: default;
    display: block;
    font-size: inherit;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
}

.fa-fw {
    width: 1.25em !important;
}

.margin-top-vh {
    margin-top: 3vh;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    max-width: 1800px;
}


/* width */

::-webkit-scrollbar {
    width: 10px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #888;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.margin-right {
    margin-right: 1vh;
}

.function-default-value-badge{
    margin-left: 1vh;
}

.function-argument-badge{
    width: -webkit-fill-available;
    margin-top: 0.5vh;
    text-align: left;
    font-size: 90%;
}