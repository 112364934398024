.dropdown-table {
  border-collapse: collapse;
  margin: 1.5em 0;
  font-size: 0.9em;
  box-shadow: 0 0 1.25em rgba(0, 0, 0, 0.15);
  background-color: #eeeeee;
  color: black;
}

h5 + .dropdown-table {
  margin-top: 1em;
}

.dropdown-table th,
.dropdown-table td {
    padding: 0.75em 1em;
}

.dropdown-table tbody tr > * {
  border-bottom: thin solid #dddddd;
}

.dropdown-table thead tr:last-child > * {
  border-bottom: thin solid darkgrey;
}

.dropdown-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.dropdown-table .left-boundary {
  border-left: thin solid darkgrey;
}

.dropdown-table span.changed {
  border-top: medium solid #ffbb67;
  border-bottom: medium solid #ffbb67;
}

.dropdown-table tbody tr th:first-child {
  text-align: right;
}