.sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 260px;
    display: block;
    z-index: 1;
    color: #fff;
    font-weight: 200;
    background-size: cover;
    background-position: center center;

    .sidebar-wrapper{
        position: relative;
        max-height: calc(100vh - 75px);
        min-height: 100%;
        overflow: auto;
        width: 260px;
        z-index: 4;
        padding-bottom: 100px;
    }

    .logo{
        padding: 10px 15px 9px 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        position: relative;
        z-index: 4;
    }

    .nav{
        margin-top: 20px;
        float: none;
        display: block;

        li{
            .nav-link{
                color: $white-color;
                margin: 5px 15px;
                opacity: .86;
                border-radius: 4px;
                display: block;
                padding: 10px 15px;

                &:hover{
                    background: rgba(255, 255, 255, 0.13);
                    opacity: 1;
                }

                p{
                    margin: 0;
                    line-height: 31px;
                    font-size: 12px;
                    font-weight: 600;
                    text-transform: uppercase;
                    display: inline-flex;
                    margin-left: 15px;
                }

                i{
                    font-size: 28px;
                    width: 30px;
                    text-align: center;
                    vertical-align: middle;
                    float: left;
                }
            }

            &:hover .nav-link{
                background: rgba(255,255,255,0.13);
                opacity: 1;
            }

            &.active .nav-link{
                color: $white-color;
                opacity: 1;
                background: rgba(255,255,255,0.23);

            }

        }

        .nav-link{
            color: $white-color;
            margin: 5px 15px;
            opacity: .86;
            border-radius: 4px;
            text-transform: uppercase;
            line-height: 30px;
            font-size: 12px;
            font-weight: 600;
        }
    }

    .logo{
        padding: $navbar-padding-a;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        p{
            float: left;
            font-size: 20px;
            margin: 10px 10px;
            color: $white-color;
            line-height: 20px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }

    }

    &[data-image]:after,
    &.has-image:after{
        opacity: .77;
    }

    &[data-color="black"]:after{
        @include icon-gradient($default-states-color, $default-states-color);
    }
}


.main-panel{
    background: rgba(203,203,210,.15);
    position: relative;
    float: right;
    width: $sidebar-width;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;

    > .content{
        padding: 30px 15px;
        flex: 1 1 auto;
    }

    > .footer{
        border-top: 1px solid #e7e7e7;
    }

    .navbar{
        margin-bottom: 0;
    }

    .footer, .navbar{
        flex: 0 0 auto;
    }
}

.sidebar,
.main-panel{
    overflow: auto;
    max-height: 100%;
    height: 100%;
    -webkit-transition-property: top,bottom;
    transition-property: top,bottom;
    -webkit-transition-duration: .2s,.2s;
    transition-duration: .2s,.2s;
    -webkit-transition-timing-function: linear,linear;
    transition-timing-function: linear,linear;
    -webkit-overflow-scrolling: touch;
}

.modal{
    &.show{
        .modal-dialog{
            -webkit-transform: translate(0, 30%);
            -o-transform: translate(0, 30%);
            transform: translate(0, 30%);
        }
    }
    &.modal-mini{
        .modal-dialog{
            max-width: 255px;
            margin: 0 auto;
        }
    }
    .modal-content{
        .modal-header{
            border-bottom: none;
            padding-top: 24px;
            padding-right: 24px;
            padding-bottom: 0;
            padding-left: 24px;

            .modal-profile{
                width: 80px;
                height: 80px;
                border-radius: 50%;
                text-align: center;
                line-height: 5.7;
                box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.3);

                i{
                    font-size: 32px;
                    padding-top: 24px;
                }
            }
        }

        .modal-body{
            padding-top: 24px;
            padding-right: 24px;
            padding-bottom: 16px;
            padding-left: 24px;
            line-height: 1.9;
        }

        .modal-body + .modal-footer{
            padding-top: 0;
        }

        .modal-footer{
            border-top: none;
            padding-right: 24px;
            padding-bottom: 16px;
            padding-left: 24px;
            -webkit-justify-content: space-between;
            justify-content: space-between;

            .btn{
                margin: 0;
                padding-left: 16px;
                padding-right: 16px;
                width: auto;

                &:hover,
                &focus{
                    text-decoration: none;
                }
            }
        }
    }
}


.wrapper {
    min-height: 100%;
    width: 100%;
    display: flex;
    background-color: transparent;
}

.sidebar {
    display: block;
    position: static;
    background-color: #4D4D4D;
}

.main-panel {
    position: static;
    background-color: transparent;
}
