.card {
  container-type: inline-size;
  --space: calc(100%-300px);
}

.tooltip-container {
  display: inline-block;
  position: relative;
}

.tooltip-target {
  font-size: 0.8em;
  transform: translateY(-0.5rem);
  display: inline-block;
  outline: none;
  cursor: help;
  & .hover-icon {
    display: none;
    font-size: 1.5rem;
  }
}

.tooltip-container:hover, .tooltip-target:focus {
  & .fa-info-circle {
    display: none;
  }
  & .hover-icon {
    display: inline-block;
  }
}

.tooltip-popup {
  display: none;
  position: absolute;
  width: 400px;
  max-width: 40vw; // For browsers that don't support container queries
  z-index: 10000;
  font-size: 0.9rem;
  line-height: 1.2rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.5rem;
  padding-right: 1rem;
  top: 0.2rem;
  left: 0.9rem;
  transform: translateY(-50%);
  backdrop-filter: blur(0.25rem);
  border: 1px solid rgba(0, 0, 0, 0.7);
  border-radius: 0.4rem;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  user-select: none;
  cursor: help;
}

.tooltip-target:hover ~ .tooltip-popup, .tooltip-target:focus ~ .tooltip-popup, .tooltip-popup:hover {
  display: block;
}

.tooltip-target:focus ~ .tooltip-popup {
  box-shadow: inset 0 0 0.25rem white;
}


@media (max-width: 569px) {
  .card-title .optional {
    display: none;
  }
}


@container (min-width: 600px) {
  .card .tooltip-popup {
    max-width: unset;
  }
}

@container (max-width: 600px) {
  .card .tooltip-popup {
    max-width: 300px;
  }
}

@container (max-width: 500px) {
  .card .tooltip-popup {
    max-width: 200px;
  }
}

@container (max-width: 400px) {
  .card .tooltip-popup {
    max-width: 40vw;
  }
}