[id="news-scroller"] {
  max-height: 250px;
}

details.accordion-item {
  border-bottom: 1px solid rgba(0,0,0,0.125);
  margin-bottom: 1.25em;
}

summary.accordion-header {
  font-size: 24px;
  user-select: none;
}

details.accordion-item[open] summary.accordion-header {
  margin-bottom: 0.5em;
}

details.accordion-item[open] h4 {
  margin-top: 20px;
}